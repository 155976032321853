/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
    if ('serviceWorker' in navigator) {
        console.log('browser supports service workers');
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            let needReload = false;
            for(let registration of registrations) {
                console.log("registration", registration)
                registration.unregister()
                needReload = true;
            }
            if (needReload === true) {
                console.log("needReload", needReload)
                window.location.reload()
            }

        }).catch(function(err) {
            console.log('Service Worker unregister failed: ', err);
        });
    }
}